/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //alert user to staging Environment
        var pathname = window.location.href;
        var staging = 'nerdymind';
        var info = localStorage.getItem("staging") || "";
        if(pathname.includes(staging) && info === ''){
          console.info("You are viewing the Staging Environment.");
          var redirect = confirm('You are viewing staging. Click "OK" to be redirected to the live site.');
          if(redirect === true){
            window.location.replace("http://lionscrestmanor.com/");
          }
          localStorage.setItem('staging', '1');
        }


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //svg btn-primary
        $('.btn-primary').append('<svg id="btn-shape" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325 154"><path id="bg-color" d="M299.65,64.09H285.94l-1.74-4.45a15.11,15.11,0,0,0-13.95-9.57c-45.7,0-79.18-9.61-105.34-30.25l-.26-.21-.26.21C138.23,40.46,104.76,50.08,59.06,50.08a15.11,15.11,0,0,0-13.95,9.57l-1.74,4.45H29.65a12.53,12.53,0,0,0-12.5,12.35c0,.06,0,.12,0,.18V77a12.53,12.53,0,0,0,12.5,12.32H43.37l1.74,4.45a15.11,15.11,0,0,0,13.95,9.57c45.7,0,79.18,9.61,105.34,30.25l.26.21.26-.21c26.16-20.64,59.64-30.25,105.34-30.25a15.11,15.11,0,0,0,13.95-9.57l1.74-4.45h13.72A12.53,12.53,0,0,0,312.15,77q0-.15,0-.3v-.26A12.53,12.53,0,0,0,299.65,64.09Z" style="stroke-miterlimit:10;stroke-width:1.5px"/><path id="bg-color" d="M319.15,76.59a19.56,19.56,0,0,0-19.5-19.5h-8.94a22,22,0,0,0-20.46-14c-35.91,0-72.22-6-101-28.75-1.31-1-3-3-4.59-3.56-1.62.54-3.29,2.53-4.59,3.56C131.28,37,95,43.08,59.06,43.08a22,22,0,0,0-20.46,14H29.65a19.56,19.56,0,0,0-19.5,19.5s0,.09,0,.13,0,.09,0,.13a19.56,19.56,0,0,0,19.5,19.5h8.94a22,22,0,0,0,20.46,14c35.91,0,72.22,6,101,28.75,1.31,1,3,3,4.59,3.56,1.62-.54,3.29-2.53,4.59-3.56,28.78-22.7,65.1-28.75,101-28.75a22,22,0,0,0,20.46-14h8.94a19.56,19.56,0,0,0,19.5-19.5s0-.09,0-.13S319.15,76.63,319.15,76.59Z" style="fill:none;stroke-miterlimit:10;stroke-width:2px"/></svg>');

        //for homepage banner   
        $('#banner-image').owlCarousel({
            loop:true,
            center: true,
            items:1,
            autoplay: true,
            autoplaySpeed: 1500,
            autoplayTimeout: 3500,
            fluidSpeed: 800,
            smartSpeed: 800,
            nav:false
        })

        $("[id*='featured-image']").owlCarousel({
            loop:false,
            center: true,
            items:1,
            autoplay: true,
            autoplaySpeed: 500,
            autoplayTimeout: 15000,
            fluidSpeed: 500,
            smartSpeed: 500,
            margin:10,
            nav:true,
            navText:["<i class='arrow-left'></i>","<i class='arrow-right'></i>"],
        })

        $('#gallery-image').owlCarousel({
            loop:false,
            center: false,
            items:3,
            autoplay: true,
            autoplaySpeed: 500,
            autoplayTimeout: 15000,
            fluidSpeed: 500,
            smartSpeed: 500,
            nav:true,
            margin:35,
            stagePadding: 35,
            navText:["<i class='arrow-left'></i>","<i class='arrow-right'></i>"],
            responsive:{
                0:{
                  items:2
                },
                600:{
                  items:2
                },
                1000:{
                  items:3
                }
            }
        })

        //mixItUp - https://github.com/patrickkunka/mixitup
        $(function(){
            $('#Container').mixItUp({
              animation: {
                reverseOut: true,
                easing: 'ease',
                duration: 530,
            		effects: 'fade stagger(34ms) scale(1.12)',
            		easing: 'ease',
                animateResizeTargets: true
              }
            });
            

            
            
        });
        window.lazySizesConfig = window.lazySizesConfig || {};
        window.lazySizesConfig.expand = 9;

        $('.fw-box').each(function(){
          link = $(this);
          text = link.html();
          text_number = text.length;
          if(text_number >= 19){
            link.addClass('twolines');
          }else if(text_number >= 31){
            link.addClass('threelines');
          }
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
